<template>
    <div>
        <v-main>
            <v-container fluid>
                <v-card>
                    <v-col cols="3">
                        <v-select
                            v-model="filterStatus"
                            :items="listStatus"
                            item-text="name"
                            item-value="id"
                            placeholder="Status Penarikan">

                        </v-select>
                    </v-col>
                    <v-data-table
                        :headers="headers"
                        :items="listCashWithdrawal"
                        :options.sync="pagination"
                        :loading=loading
                        :footer-props="footerProps"
                        :server-items-length="cashWithdrawalCount"
                    >
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-dialog
                                v-model="dialog"
                                max-width="500px"
                                >
                                <v-card>
                                    <v-card-text>
                                        <v-container>
                                            <v-row dense>
                                                <h5>Rincian Penarikan</h5>
                                                <v-col
                                                    cols="12"
                                                    md="12"
                                                    align="left"
                                                    class="ma-0"
                                                >
                                                    <table width="450">
                                                        <tr>
                                                            <td width="100">No Invoice</td>
                                                            <td width="20" align="center">:</td>
                                                            <td>{{item!=null?item.invoiceNo:''}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td width="110">Status Penarikan</td>
                                                            <td width="20" align="center">:</td>
                                                            <td><b>{{item!=null?item.status:''}}</b></td>
                                                        </tr>
                                                        <tr v-if="(item!=null?item.status:'')==='Cancelled'">
                                                            <td width="110">Alasan ditolak</td>
                                                            <td width="20" align="center">:</td>
                                                            <td><b>{{item!=null?item.information:''}}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td width="110">Tgl Pengajuan</td>
                                                            <td width="20" align="center">:</td>
                                                            <td>{{item!=null?item.billDate:''}}</td>
                                                        </tr>
                                                        <template v-if="(item!=null?item.status:'')==='Paid'">
                                                            <tr>
                                                                <td width="110">Tgl Dibayar</td>
                                                                <td width="20" align="center">:</td>
                                                                <td>{{item!=null?item.transactionDate:''}}</td>
                                                            </tr>
                                                        </template>
                                                        <tr>
                                                            <td colspan="3"><v-divider /></td>
                                                        </tr>
                                                        <tr>
                                                            <td width="100">No Anggota</td>
                                                            <td width="20" align="center">:</td>
                                                            <td>{{item!=null?item.member.memberNo:''}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td width="100">Nama</td>
                                                            <td width="20" align="center">:</td>
                                                            <td>{{item!=null?item.member.fullName:''}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="3"><v-divider /></td>
                                                        </tr>
                                                        <tr>
                                                            <td width="100">Nama Rekening</td>
                                                            <td width="20" align="center">:</td>
                                                            <td>{{item!=null?item.account.accountName:''}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td width="100">Nomor Rekening</td>
                                                            <td width="20" align="center">:</td>
                                                            <td>{{item!=null?item.account.accountNumber:''}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td width="100">Bank</td>
                                                            <td width="20" align="center">:</td>
                                                            <td>{{item!=null?item.account.bankName:''}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td width="100"><b>Total Transfer</b></td>
                                                            <td width="20" align="center">:</td>
                                                            <td><b>{{item!=null?item.amountOut:''}}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td width="100"></td>
                                                            <td width="20"></td>
                                                            <td>{{$root.terbilang(item!=null?item.amountOut:'')}}</td>
                                                        </tr>
                                                    </table>
                                                    <br />
                                                    <br />
                                                    <template v-if="(item!=null?item.status:'')==='Pending'">
                                                        <v-btn color="success" class="mr-3" @click="paid.isPaid=true" :disabled="(paid.isPaid || rejected.isRejected)">Paid</v-btn>
                                                        <v-btn color="error" @click="rejected.isRejected=true" :disabled="(paid.isPaid || rejected.isRejected)">Rejected</v-btn>
                                                    </template>
                                                </v-col>
                                            </v-row>
                                            <template v-if="paid.isPaid">
                                                <v-row>
                                                    <v-col>
                                                        <v-text-field
                                                        v-model="paid.transactionNo"
                                                        label="No Transaksi"
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col>
                                                        <v-text-field
                                                        v-model="paid.transactionDate"
                                                        type="date"
                                                        label="Tanggal Transfer"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row dense>
                                                    <p style="color:red;">{{errMsg}}</p>
                                                </v-row>
                                                <v-row>
                                                    <v-btn text color="primary" :loading="isProcess" @click="processPaid()">Kirim</v-btn>
                                                    <v-btn text color="error" @click="reset">Batal</v-btn>
                                                </v-row>
                                            </template>
                                            <template v-if="rejected.isRejected">
                                                <v-row>
                                                    <v-col>
                                                        <v-text-field
                                                        v-model="rejected.remark"
                                                        label="Keterangan penolakan"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row dense>
                                                    <p style="color:red;">{{errMsg}}</p>
                                                </v-row>
                                                <v-row>
                                                    <v-btn text color="primary" :loading="isProcess" @click="processRejected()">Kirim</v-btn>
                                                    <v-btn text color="error" @click="reset">Batal</v-btn>
                                                </v-row>
                                            </template>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                                </v-dialog>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                        <v-chip
                            :color="getColor(item.status)"
                            dark
                            small
                        >
                            {{ item.status }}
                        </v-chip>
                        </template>
                        <template v-slot:[`item.actions`]="{item}">
                            <v-icon color="green" @click="detailWithdrawal(item)">mdi-eye-outline</v-icon>
                        </template>
                    </v-data-table>
                </v-card>
            </v-container>
            <v-snackbar
                :value="snackbar"
                timeout="3000">
                    {{snackMsg}}
            </v-snackbar>
        </v-main>
        <OverlayProgress :overlay=$root.overlay :isError=$root.isError />
    </div>
</template>
<script>
import OverlayProgress from '@/components/OverlayProgress.vue'
export default {
    components: {
        OverlayProgress
    },
    data() {
        return {
            filterStatus:null,
            listStatus: [
                {id:0, name:'Semua'},
                {id:6, name:'Paid'},
                {id:7, name:'Pending'},
                {id:9, name:'Cancelled'},
            ],
            headers: [
                { text: 'No Anggota', align: 'start', sortable: false, value: 'member.memberNo', },
                { text: 'Nama', sortable: false, value: 'member.fullName' },
                { text: 'Nominal Penarikan', sortable: false, value: 'amountOut' },
                { text: 'Bank', sortable: false, value: 'bankName' },
                { text: 'Status Penarikan', sortable: false, value: 'status' },
                { text: '', value: 'actions', sortable: false },
            ],
            listCashWithdrawal: [],
            pagination: {
                page: 1,
                itemsPerPage: 20,
            },
            footerProps: {
                itemsPerPageOptions: [20, 50, 100, -1],
                showFirstLastPage: true,
                showCurrentPage: true,
            },
            loading: true,
            isProcess: false,
            cashWithdrawalCount: 0,
            dialog: false,
            item:null,
            bodyParam:null,
            snackbar: false,
            snackMsg: null,
            errMsg: null,
            paid: {
                isPaid: false,
                transactionNo: null,
                transactionDate: null
            },
            rejected: {
                isRejected: false,
                remark: null
            }
        }
    },
    watch: {
        pagination:{
            handler(){
                this.getData(this.filterStatus)
            },
            deep:true
        },
        'filterStatus': function() {
            this.getData(this.filterStatus)
        },
        'paid.transactionNo': function() {
            this.errMsg = null
        },
        'paid.transactionDate': function() {
            this.errMsg = null
        },
        'rejected.remark': function() {
            this.errMsg = null
        }
    },
    methods: {
        async getData(filterStatus) {
            if (filterStatus===undefined || filterStatus===null || filterStatus==0) filterStatus = ''
            this.loading=true
            var api = this.$root.api.get.cashWithdrawal+'?statusCode='+filterStatus
            const {page, itemsPerPage} = this.pagination
            if (itemsPerPage > -1) {
                api = api+'&pageNo='+page+'&rowPerPage='+itemsPerPage
            }
            await this.$root.api.sentAPI(
            {
                method: 'get',
                api: api,
            }).then((res) => {
                if (res.responseStatus.responseCode==='00') {
                    this.cashWithdrawalCount = res.data.count
                    this.listCashWithdrawal = res.data.list
                }
            })
            .catch((err) => {
                this.$root.isError = true
                if(!navigator.onLine) this.$root.isError = err.message
            })
            if(!this.$root.isError) this.$root.overlay = false
            this.loading=false
        },
        getColor(status) {
            if (status === 'Paid') return 'green'
            else if (status === 'Cancelled') return 'black'
            else return 'red'
        },
        detailWithdrawal(item) {
            this.reset()
            this.dialog = true
            this.item=item
            console.log(JSON.stringify(item))
        },
        processPaid() {
            if(this.$root.isEmpty(this.paid.transactionNo) || this.$root.isEmpty(this.paid.transactionDate)) {
                this.errMsg = 'Harap isi semua'
                return
            }
            this.passingBody(6)
            this.processApproval()
        },
        processRejected() {
            if(this.$root.isEmpty(this.rejected.remark)) {
                this.errMsg = 'Harap isi'
                return
            }
            this.passingBody(9)
            this.processApproval()
        },
        async processApproval() {
            this.isProcess = true
            var api = this.$root.api.post.cashWithdrawal
            await this.$root.api.sentAPI(
            {
                method: 'post',
                api: api,
                form: this.bodyParam
            }).then((res) => {
                if (res.responseStatus.responseCode==='00') {
                    this.getData(this.filterStatus)
                    this.snackbar = true
                    this.snackMsg = 'Update Status Sukses'
                }
                this.dialog = false
                this.reset()
            })
            .catch((err) => {
                this.$root.isError = true
                if(!navigator.onLine) this.$root.isError = err.message
            })
            if(!this.$root.isError) this.$root.overlay = false
        },
        passingBody(statusId) {
            this.bodyParam = {
                remark: this.rejected.remark,
                maintenanceStatus: statusId,
                memberId: this.item.member.memberId,
                paymentId: this.item.paymentId,
                referenceNo: this.paid.transactionNo,
                transactionDate: this.paid.transactionDate
            }
        },
        reset() {
            this.paid.isPaid = false
            this.paid.transactionNo = null
            this.paid.transactionDate = null
            this.rejected.isRejected = false
            this.rejected.remark = null
            this.errMsg = null
            this.isProcess = false
        }
    },
    mounted() {
        this.getData(this.filterStatus)
    }
}
</script>